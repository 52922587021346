import { render, staticRenderFns } from "./riverTunnelHt.vue?vue&type=template&id=585f566e&scoped=true&"
import script from "./riverTunnelHt.vue?vue&type=script&lang=js&"
export * from "./riverTunnelHt.vue?vue&type=script&lang=js&"
import style0 from "./riverTunnelHt.vue?vue&type=style&index=0&id=585f566e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "585f566e",
  null
  
)

export default component.exports